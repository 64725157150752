.container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.viewMoreDropdown {
  display: flex;
  align-items: center;
  font-size: 12px;
}
