:root {
  --mobile-ideal-width: 600px;

  --primary-color-blue: #022472;
  --primary-color-red: #e84855;
  --secondary-color-blue: #d7e3fe;
  --secondary-color-red: #fbdbde;
  --secondary-color-grey: #172b4d14;
  --primary-background-color-grey: #f5f5f7;
  --red: #ff0000;
  --white: #ffffff;
  --black: #000000;

  --chart-color-1: #00bdd4;
  --chart-color-2: #4d94fe;
  --chart-color-3: #f58fb1;
  --chart-color-4: #fec602;
  --chart-color-5: #2cc297;
}

*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  cursor: default;
}

*::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

*::-webkit-scrollbar-track-piece {
  background-color: #f1f1f1;
}

body {
  margin: 0;
  background-color: #f5f5f7;
  font-family:
    'Open Sans',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
