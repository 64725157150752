.nav {
  height: 85px;
  width: 100%;
  max-width: 1000px;
  align-items: center;
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
}

.logo {
  height: 50px;
}

.mobileLogo {
  height: 40px;
  margin-left: 10px;
}

.mobileBurgerIcon {
  margin-right: 10px;
  color: var(--primary-color-blue);
  font-size: 25px;
  cursor: pointer;
}

.mobileNav {
  display: none;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
}

.drawer {
  width: 70vw;
  height: 100vh;
  z-index: 100;
  background-color: var(--white);
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  transition: 0.3s ease-in-out;
  box-shadow:
    0px 8px 16px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.06),
    0px 0px 2px 0px rgba(0, 0, 0, 0.1);
}

.activeDrawer {
  right: 0;
}

.inactiveDrawer {
  right: -80vw;
}

.drawerBackground {
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: var(--black);
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
}

.drawerNavLink {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

@media (max-width: 1000px) {
  .nav {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .nav {
    display: none;
  }

  .mobileNav {
    display: flex;
  }
}
