.container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0px 10px;
}

.item {
  min-width: 150px;
  cursor: pointer;
  color: var(--primary-color-blue);
  border-radius: 5px;
}

.item:hover {
  font-weight: 500;
  background-color: var(--secondary-color-grey);
}

.itemNavLink {
  padding: 10px;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--primary-color-blue);
}
