.viewItem {
  border-radius: 16px 16px 0px 0px;
  padding: 0px 25px;
  align-items: center;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  color: var(--primary-color-blue);
}

.inactiveViewItem:hover {
  background-color: var(--secondary-color-grey);
}

.activeViewItem {
  background-color: #ffffff;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: none;
}
