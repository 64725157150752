.container {
  width: 90%;
  max-width: 1000px;
}

@media (max-width: 600px) {
  .container {
    width: 95%;
  }
}
