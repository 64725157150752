.activeLink,
.inactiveLink {
  color: var(--primary-color-blue);
  text-decoration: none;
  font-size: large;
}

.activeLink {
  font-weight: 600;
}

.divider {
  width: 75%;
  margin-top: 5px;
  border-bottom: 2px solid var(--primary-background-color-grey);
}

.activeDivider {
  border-bottom: 2px solid var(--primary-color-blue);
}

@media (max-width: 600px) {
  .activeLink,
  .inactiveLink {
    color: var(--primary-color-blue);
    text-decoration: none;
    font-size: 15px;
  }

  .divider {
    border-bottom: 2px solid var(--white);
  }

  .divider.activeDivider {
    border-bottom: 2px solid var(--primary-color-blue);
  }
}
